import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';

import { MessageService, ConfirmationService } from 'primeng/api';
import { Router } from '@angular/router';
import { General } from 'src/app/model/General';
import { ServiciosFirmaService } from 'src/app/service/servicios-firma.service';
import { RespuestaGenerica } from 'src/app/model/clasesGenericas';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent extends General implements OnInit {

  public loading: boolean;

  constructor(
    private router: Router,
    private message: MessageService,
    private confirmationService: ConfirmationService,
    private serviceLogout: ServiciosFirmaService
  ) {
    super(router, message);
    this.loading = false;
  }

  items: MenuItem[];

  ngOnInit() {
    this.items = [
      {
        icon: 'pi pi-home',
        style: { 'font-size': '1.5rem', 'margin-left': '5px' },
        command: () => this.home(),
        routerLink: '/firma-digital/homeAsociado',
      },
      {
        label: 'Salir',
        icon: 'pi pi-sign-out',
        command: () => this.cerrarSesion(),
      },
    ];
  }

  cerrarSesion() {
    this.confirmationService.confirm({
      message: '¿Deseas cerrar la sesión?',
      header: 'Vas a cerrar sesión',
      icon: 'pi pi-sign-out',
      acceptIcon: 'pi pi-check',
      rejectIcon: 'pi pi-times',
      acceptLabel: 'Sí, cerrar sesión',
      rejectLabel: 'Cancelar',
      accept: () => {
        this.loading = true;
        if (sessionStorage.getItem('user') === 'cliente') {
          this.redirigirRuta('sencilla', '/firma-digital/validarIdent', null);
          sessionStorage.clear();
        } else {
          this.serviceLogout.logout(this.getCodUser('jwt')).subscribe(
            (data: RespuestaGenerica) => {
              this.loading = false;
              if (data.bRta) {
                this.redirigirRuta(
                  'sencilla',
                  '/firma-digital/loginAdmin',
                  null
                );
                sessionStorage.clear();
              } else {
                this.enviarMensaje('warn', '¡ALERTA!', data.sMsj);
              }
            },
            () => {
              this.loading = false;
              this.enviarMensaje(
                'warn',
                '¡ERROR!',
                'Hubo un inconveniente en la conexión'
              );
            }
          );
        }
        
      },
    });
  }

  home() {
    if (sessionStorage.getItem('user') === 'cliente') {
      this.redirigirRuta('sencilla', '/firma-digital/homeAsociado', null);
    } else {
      this.redirigirRuta('sencilla', '/firma-digital/homeAdmin', null);
    }
  }

  /*cerrarSesion() {
    this.confirmationService.confirm({
      message: '¿Deseas cerrar la sesión?',
      header: 'Vas a cerrar sesión',
      icon: 'pi pi-sign-out',
      acceptIcon: 'pi pi-check',
      rejectIcon: 'pi pi-times',
      acceptLabel: 'Sí, cerrar sesión',
      rejectLabel: 'Cancelar',
      accept: () => {
        this.loading = true;
        this.loginService.logout(this.jwtService.getCodUser()).then(
          (respuesta: any) => {
            this.loading = false;
            if (respuesta.bRta == true) {
              this.loading = true;
              this.jwtService.clearStorage();
              localStorage.setItem('cerrar', 'true');
              this.ruta.navigateByUrl('/');
            } else {
              this.messageService.clear();
              this.messageService.add({
                severity: 'error',
                summary: respuesta.sMsj,
                sticky: true,
              });
              //Mostrar pop up error
              //this.tipoModal = "error";
              //this.mensaje = respuesta.sMsj;
              //this.ruta.navigateByUrl('/homePage/estado-cuenta');
            }
          },
          (error) => {
            this.messageService.clear();
            this.messageService.add({
              severity: 'error',
              summary: 'Ha ocurrido un error inesperado',
              sticky: true,
            });
            //this.tipoModal = "error";
            //this.mensaje = "Ha ocurrido un error inesperado";
          }
        );
      },
    });

    
  }*/
}

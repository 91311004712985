import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appPercentage]'
})
export class PercentageDirective {

  constructor(private el: ElementRef) { }

  @HostListener('input', ['$event']) onInputChange(event: Event) {
    const input = event.target as HTMLInputElement;
    let value = input.value.trim();
    
    // Remover el símbolo de porcentaje si está presente
    value = value.replace('%', '');

    // Convertir el valor a decimal
    const parsedValue = parseFloat(value);

    if (!isNaN(parsedValue)) {
      // Formatear el valor como porcentaje
      input.value = `${parsedValue.toFixed(2)}%`;
    } else {
      input.value = ''; // Manejar casos inválidos
    }
  }

  @HostListener('blur', ['$event']) onBlur(event: Event) {
    const input = event.target as HTMLInputElement;
    let value = input.value.trim();

    // Remover el símbolo de porcentaje y convertir a decimal
    value = value.replace('%', '');
    const parsedValue = parseFloat(value) / 100; // Convertir a decimal

    // Actualizar el valor en el campo formControlName
    // Esto asume que tienes acceso al FormGroup desde este contexto
    // Ejemplo: this.frmNotificarAsociado.get('sPorcentaje').setValue(parsedValue);
  }
}

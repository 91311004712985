import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import {
  RequestValAfiliacion,
  RequestValToken,
  RequestValIdentidad,
  SolicitudFirmaRta,
  RequestLoginAdmin,
} from '../model/clasesAfiliacion';
import { RespuestaGenerica } from '../model/clasesGenericas';

@Injectable({
  providedIn: 'root',
})
export class ServiciosFirmaService {
  public server = environment.URL_SERVICE;

  headers = new HttpHeaders({
    Accept: 'application/json;charset=UTF-8',
    'Content-Type': 'application/json;charset=UTF-8',
    Authorization: 'Bearer' + sessionStorage.getItem('token'),
  });

  constructor(private http: HttpClient) {}

  consultarAfiliacion(requestValAfil: RequestValAfiliacion) {
    const path = this.server + 'WSFirma/consultarAfiliacion';
    return this.http.post(path, requestValAfil, { headers: this.headers });
  }

  reenviarCodigo(requestValAfil: RequestValAfiliacion) {
    const path = this.server + 'WSFirma/reenviarCodigo';
    return this.http.post(path, requestValAfil, { headers: this.headers });
  }

  validarTokenAcceso(requestValToken: RequestValToken) {
    const path = this.server + 'WSFirma/validarToken';
    return this.http.post(path, requestValToken, { headers: this.headers });
  }

  obtenerSolicitudes(id: number) {
    const path = this.server + 'WSFirma/obtenerSolicitudesById?idSolAfil=' + id;
    return this.http.get(path, { headers: this.headers });
  }

  generarProcesoFirma(id: number) {
    const path = this.server + 'WSFirma/generarProcesoFirma?idSolAfil=' + id;
    return this.http.post(path, { headers: this.headers });
  }

  guardarCampos(id: number) {
    const path = this.server + 'WSFirma/guardarCampos?idSolAfil=' + id;
    return this.http.post(path, { headers: this.headers });
  }

  validarIdentidad(requestValIdent: RequestValIdentidad) {
    const path = this.server + 'WSFirma/validarIdentidad';
    return this.http.post(path, requestValIdent, { headers: this.headers });
  }

  actualizarEstado(id: number) {
    const path = this.server + 'WSFirma/actualizarEstado?idSolAfil=' + id;
    return this.http.post(path, { headers: this.headers });
  }
  obtenerDocumento(id: number) {
    const path = this.server + 'WSFirma/obtenerDocumento?idSolAfil=' + id;
    return this.http.post(path, { headers: this.headers });
  }

  obtenerDetalleFirmaDocuemnto(id: number) {
    const path =
      this.server + 'WSFirma/obtenerDetalleFirmaDocumento?idSolAfil=' + id;
    return this.http.post(path, { headers: this.headers });
  }

  reenviarOTP(id: number) {
    const path =
      this.server + 'WSFirma/reenviarOTP?idSolAfil=' + id;
    return this.http.post(path, { headers: this.headers });
  }

  loginAdmin(requestLoginAdmin: RequestLoginAdmin) {
    const path = this.server + 'loginAdmin';
    return this.http.post(path, requestLoginAdmin, { headers: this.headers });
  }

  obtenerSolicitudes2() {
    const path = this.server + 'WSFirma/obtenerSolicitudes';
    return this.http.get(path, { headers: this.headers });
  }

  logout(codigoUser:number) {
    const path = this.server +"CerrarSession?iUsuario="+codigoUser;
    return this.http.get<RespuestaGenerica>(path);
  }

  loginUsuarioEmpleado(xiTipoPerfil: string, xTipoIdent: string, xIdent: string, xClave: string, xNavegador: string) {
    let path = this.server + "/LoginPinc/Empleado/loginUsuarioEmpleado";
    path += "?iTipoPerfil=" + xiTipoPerfil;
    path += "&xiTipoIden=" + xTipoIdent;
    path += "&xcIdentificacion=" + xIdent;
    path += "&xcClave=" + xClave;
    path += "&xcNavegador=" + xNavegador;
    path += "&xiTipoCanal=1";
    return this.http.post<string>(path, { headers: this.headers });
  }
}

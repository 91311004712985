import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appCurrencyFormat]'
})
export class CurrencyFormatDirective {

  constructor(private el: ElementRef, private control: NgControl) { }

  @HostListener('input', ['$event.target.value'])
  onInput(value: string) {
    // Remover cualquier caracter que no sea un dígito
    const amount = value.replace(/\D/g, '');
    
    // Formatear el valor como número con separador de miles
    if (amount) {
      this.control.valueAccessor.writeValue(
        parseInt(amount, 10).toLocaleString('es-ES')
      );
    }
  }
}

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PagareLoginService {

  public url = environment.URL_SERVICE;
  public headers: any;

  constructor(private http: HttpClient) {
    this.headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
  }

  login(xiTipoPerfil: number, xTipoIdent: number, xIdent: string, xClave: string, xNavegador: string){
    let path =this.url + '/LoginPinc/Cliente/loginCliente';
    path += "?iTipoPerfil=" + xiTipoPerfil;
    path += "&xiTipoIden=" + xTipoIdent;
    path += "&xcIdentificacion=" + xIdent;
    path += "&xcClave=" + xClave;
    path += "&xcNavegador=" + xNavegador;
    path += "&xiTipoCanal=1";
    return this.http.post(path, { headers: this.headers });
  }

  cerrarSession(xiUsuario: string){
    let path =this.url + '/LoginPinc/CerrarSession';
    path += "?iUsuario=" + xiUsuario;
    path += "&idTipoCanal=1";
    return this.http.post(path, { headers: this.headers });
  }

}

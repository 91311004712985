import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSpinnerModule } from 'ngx-spinner';
import { PrimeNgModule } from './prime-ng.module';
import { MenuComponent } from '../pages/menu/menu.component';
import { TableModule } from 'primeng/table';
import { ToolbarModule } from 'primeng/toolbar';
import { SpinnerComponent } from '../pages/spinner/spinner.component';
import { TranslateModule } from '@ngx-translate/core';
import { MenuAdminComponent } from '../pages/menu-admin/menu.component';

@NgModule({
  declarations: [MenuComponent, SpinnerComponent,MenuAdminComponent],
  imports: [
    CommonModule,
    FormsModule,
    NgxSpinnerModule,
    ReactiveFormsModule,
    PrimeNgModule,
    TableModule,
    ToolbarModule,
    TranslateModule,
  ],
  exports: [
    FormsModule,
    NgxSpinnerModule,
    ReactiveFormsModule,
    PrimeNgModule,
    TableModule,
    MenuComponent,
    MenuAdminComponent,
    ToolbarModule,
    TranslateModule,
    SpinnerComponent,
  ],
})
export class CompartidoModule {}

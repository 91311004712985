// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //Local
  //  URL_SERVICE: 'http://localhost:8080/WSRestCOOPEXTRAISS/EBSRest',///---------- LOCALMENTEs
   URL_SERVICE: 'https://tspinccoopextraissbe.ebsproductodigital.com/WSRestCOOPEXTRAISS/EBSRest',///-------------PRUEBAS

};

// ng build --output-hashing=all --prod --base-href /     //COMANDO PARA PRODUCCION DE PINC

// ng build --output-hashing=all --base-href /FecPinc/  //COMANDO PARA SUBIR A PRUEBAS

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */

// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';

import { MessageService, ConfirmationService } from 'primeng/api';
import { Router } from '@angular/router';
import { General } from 'src/app/model/General';
import { ServiciosFirmaService } from 'src/app/service/servicios-firma.service';
import { RespuestaGenerica } from 'src/app/model/clasesGenericas';
import { TokenImpService } from 'src/app/service/token-imp';
import { PagareLoginService } from 'src/app/service/pagare-login.service';

@Component({
  selector: 'app-menu-admin',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuAdminComponent extends General implements OnInit {
  public loading: boolean;

  constructor(
    private router: Router,
    private message: MessageService,
    private confirmationService: ConfirmationService,
    private serviceLogout: ServiciosFirmaService,
    private token: TokenImpService,
    private pagareLoginService: PagareLoginService
  ) {
    super(router, message);
    this.loading = false;
  }

  items: MenuItem[];

  ngOnInit() {
    this.items = [
      {
        icon: 'pi pi-home',
        style: { 'font-size': '1.5rem', 'margin-left': '5px' },
        command: () => this.home(),
        routerLink: '/firma-digital/homeAsociado',
      },
      {
        label: 'Pagaré',
        command: () => this.pagare(),
        icon: '',
      },
      // {
      //   label: 'Reiniciar Clave',
      //   icon: '',
      // },
      {
        label: 'Salir',
        icon: 'pi pi-sign-out',
        command: () => this.cerrarSesion(),
      },
    ];
  }

  cerrarSesion() {
    this.confirmationService.confirm({
      message: '¿Deseas cerrar la sesión?',
      header: 'Vas a cerrar sesión',
      icon: 'pi pi-sign-out',
      acceptIcon: 'pi pi-check',
      rejectIcon: 'pi pi-times',
      acceptLabel: 'Sí, cerrar sesión',
      rejectLabel: 'Cancelar',
      accept: () => {
        this.loading = true;
        let codigoUsuario = this.token.getCodUserEmpleado();
        console.log(codigoUsuario);
        this.pagareLoginService
          .cerrarSession(codigoUsuario.toString())
          .subscribe((response: any) => {
            this.loading = false;
            if (response.bRta) {
              this.token.clearStorage();
              this.login();
            } else {
              this.enviarMensaje(
                'warn',
                '¡Alerta!',
                response.sMsj
              );
            }
          }, (error) => {
            this.enviarMensaje(
              'warn',
              '¡ERROR!',
              'Hubo un inconveniente en la conexión'
            );
          });
      },
    });
  }

  home() {
    this.redirigirRuta('sencilla', '/firma-digital/inicio', null);
  }

  pagare() {
    this.redirigirRuta('sencilla', '/firma-digital/listarSolPagare', null);
  }

  login() {
    this.redirigirRuta('sencilla', '/firma-digital/iniciarSesion', null);
  }

  /*cerrarSesion() {
    this.confirmationService.confirm({
      message: '¿Deseas cerrar la sesión?',
      header: 'Vas a cerrar sesión',
      icon: 'pi pi-sign-out',
      acceptIcon: 'pi pi-check',
      rejectIcon: 'pi pi-times',
      acceptLabel: 'Sí, cerrar sesión',
      rejectLabel: 'Cancelar',
      accept: () => {
        this.loading = true;
        this.loginService.logout(this.jwtService.getCodUser()).then(
          (respuesta: any) => {
            this.loading = false;
            if (respuesta.bRta == true) {
              this.loading = true;
              this.jwtService.clearStorage();
              localStorage.setItem('cerrar', 'true');
              this.ruta.navigateByUrl('/');
            } else {
              this.messageService.clear();
              this.messageService.add({
                severity: 'error',
                summary: respuesta.sMsj,
                sticky: true,
              });
              //Mostrar pop up error
              //this.tipoModal = "error";
              //this.mensaje = respuesta.sMsj;
              //this.ruta.navigateByUrl('/homePage/estado-cuenta');
            }
          },
          (error) => {
            this.messageService.clear();
            this.messageService.add({
              severity: 'error',
              summary: 'Ha ocurrido un error inesperado',
              sticky: true,
            });
            //this.tipoModal = "error";
            //this.mensaje = "Ha ocurrido un error inesperado";
          }
        );
      },
    });


  }*/
}

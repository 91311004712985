import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/firma-digital/iniciarSesion',
    pathMatch: 'full',
  },
  {
    path: 'solicitudAfiliacion',
    loadChildren: () =>
      import('./solicitud-afiliacion/solicitud-afiliacion.module').then(
        (m) => m.SolicitudAfiliacionModule
      ),
  },
  {
    path: 'firma-digital',
    loadChildren: () =>
      import('./firma-digital/firma-digital.module').then(
        (m) => m.FirmaDigitalModule
      ),
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: '/firma-digital/iniciarSesion',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{ useHash:true})],
  exports: [RouterModule],
})
export class AppRoutingModule {}

import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
export class General {
  constructor(private ruta: Router, private messageService: MessageService) {}

  enviarMensaje(tipoMensaje: string, titulo: string, mensaje: string) {
    this.messageService.clear();
    this.messageService.add({
      severity: tipoMensaje,
      summary: titulo,
      detail: mensaje,
      life: 10000,
    });
  }

  redirigirRuta(tipoRuta: string, ruta: string, indicativo: string) {
    switch (tipoRuta) {
      case 'sencilla':
        this.ruta.navigate([ruta]);
        break;
      case 'indicador':
        this.ruta.navigate([ruta, indicativo]);
        break;
    }
  }

  guardarToken(nomToken: string, token: string) {
    sessionStorage.setItem(nomToken, token);
  }

  getToken(nomToken: string): string {
    let token = sessionStorage.getItem(nomToken);
    if (token != undefined && token != null && token != '') {
      return token;
    } else {
      return '';
    }
  }

  getbRta(nomToken: string): boolean {
    let bRtaToken: boolean;
    let datosLogin = this.datosLogin(nomToken);
    if (datosLogin != null && datosLogin != '') {
      let valorToken = JSON.parse(datosLogin);
      bRtaToken = valorToken.valToken.bRta;
    } else {
      bRtaToken = false;
    }
    return bRtaToken;
  }

  datosLogin(nomToken: string): string {
    let datosLoginSucces: string;
    let token: string = this.getToken(nomToken);
    if (token != '') {
      let spliToken = token.split('.');
      datosLoginSucces = window.atob(spliToken[1]);
    } else {
      datosLoginSucces = '';
    }
    return datosLoginSucces;
  }

  getsMsj(nomToken: string): string {
    let sMsj: string;
    let datosLogin = this.datosLogin(nomToken);
    if (datosLogin != null && datosLogin != '') {
      let valorToken = JSON.parse(datosLogin);
      sMsj = decodeURIComponent(escape(valorToken.valToken.sMsj));
    } else {
      sMsj = '';
    }
    return sMsj;
  }

  public isAuthenticated(nomToken: string): boolean {
    const token = sessionStorage.getItem(nomToken);
    return !this.isTokenExpired(token + '');
  }

  isTokenExpired(nomToken: string, token?: string): boolean {
    if (!token) {
      token = this.getToken(nomToken);
    }
    if (!token) {
      return true;
    }
    const date = this.getTokenExpirationDate(token);
    if (date === undefined) {
      return false;
    }
    return !(date.valueOf() > new Date().valueOf());
  }

  getTokenExpirationDate(token: string): Date {
    const decoded = this.jwtDecode(token);
    const valorToken = JSON.parse(decoded);

    if (valorToken.exp === undefined) {
      return new Date();
    }
    const date = new Date(0);
    date.setUTCSeconds(valorToken.exp);
    return date;
  }

  jwtDecode(token: string): string {
    let spliToken = token.split('.');
    let decodeToken = window.atob(spliToken[1]);
    return decodeToken;
  }

  getNomUser(nomToken: string) {
    let sMsj: string;
    let datosLogin = this.datosLogin(nomToken);
    if (datosLogin != null && datosLogin != '') {
      let valorToken = JSON.parse(datosLogin);
      sMsj = decodeURIComponent(escape(valorToken.valToken.nomUser));
    } else {
      sMsj = '';
    }
    return sMsj;
  }


  getCodUser(nomToken: string) {
    let sMsj: number;
    let datosLogin = this.datosLogin(nomToken);
    if (datosLogin != null && datosLogin != '') {
      let valorToken = JSON.parse(datosLogin);
      sMsj = Number(decodeURIComponent(escape(valorToken.valToken.codUser)));
    } else {
      sMsj = 0;
    }
    return sMsj;
  }


  getNomCliente(nomToken: string) {
    let sMsj: string;
    let datosLogin = this.datosLogin(nomToken);
    if (datosLogin != null && datosLogin != '') {
      let valorToken = JSON.parse(datosLogin);
      sMsj = decodeURIComponent(escape(valorToken.valToken.nomCliente));
    } else {
      sMsj = '';
    }
    return sMsj;
  }

  validarCamposObligatorios(formGroup: FormGroup, xValor: string) {
    console.log(formGroup, xValor);
    console.log(formGroup.get(xValor).invalid);
    if (
      formGroup.get(xValor).invalid &&
      (formGroup.get(xValor).dirty || formGroup.get(xValor).touched)
    ) {
      console.log("Entro");
      return true;
    }
    console.log("No entro");
    return false;
  }

  validarCampos(frmLogin: FormGroup, campo: string) {
    return (
      frmLogin.get(campo).invalid && frmLogin.get(campo).touched
    );
  }
}

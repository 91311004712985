import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { ToastModule } from 'primeng/toast';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { MessageService, ConfirmationService } from 'primeng/api';
import { MenubarModule } from 'primeng/menubar';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { InputSwitchModule } from 'primeng/inputswitch';
import { TooltipModule } from 'primeng/tooltip';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { InputNumberModule } from 'primeng/inputnumber';
import { DialogModule } from 'primeng/dialog';
import { CheckboxModule } from 'primeng/checkbox';
import { RadioButtonModule } from 'primeng/radiobutton';
import {MessagesModule} from 'primeng/messages';
import {MessageModule} from 'primeng/message';
import {PanelModule} from 'primeng/panel';
import { TabViewModule } from 'primeng/tabview';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    InputTextModule,
    MessageModule,
    PanelModule,
    MessagesModule,
    ButtonModule,
    ToastModule,
    MenubarModule,
    TabViewModule,
    DropdownModule,
    CalendarModule,
    AutoCompleteModule,
    InputSwitchModule,
    TooltipModule,
    ConfirmPopupModule,
    InputNumberModule,
    DialogModule,
    CheckboxModule,
    RadioButtonModule,
  ],
  exports: [
    InputTextModule,
    ButtonModule,
    ToastModule,
    MenubarModule,
    ConfirmDialogModule,
    DropdownModule,
    PanelModule,
    CalendarModule,
    MessageModule,
    MessagesModule,
    AutoCompleteModule,
    InputSwitchModule,
    TooltipModule,
    TabViewModule,
    ConfirmPopupModule,
    InputNumberModule,
    DialogModule,
    CheckboxModule,
    RadioButtonModule,
  ],
  providers: [MessageService, ConfirmationService],
})
export class PrimeNgModule {}
